import React, { useEffect } from "react";
import styled from "styled-components";
import "../../fonts/fonts.css";
import "./variables.scss";
import "./reset.scss";
import Footer from "./Footer";
import Nav from "./Nav";


function registerTouchables() {
  const touchables = document.querySelectorAll('button, button, a[href], label');
  let touchablesLength = touchables.length;
  while ( touchablesLength-- ) {
    touchables[touchablesLength].addEventListener('touchstart', () => {}, {passive: true});
    touchables[touchablesLength].addEventListener('touchend', () => {}, {passive: true});
  }
};

function adjustOutsideLinks() {
  const anchors = document.querySelectorAll('a[href]');
  let anchorsLength = anchors.length;
  while ( anchorsLength-- ) {
    if ( anchors[anchorsLength].hostname !== window.location.hostname ) {
      anchors[anchorsLength].target = '_blank';
      anchors[anchorsLength].rel = 'noopener noreferrer';
    }
  }
}

function setCanHover() {
  const canHover = !(matchMedia(`(hover: none)`).matches)
  if ( canHover ) {
    document.getElementsByTagName( 'html' )[0].classList.add(`can-hover`);
  }
  else {
    document.getElementsByTagName( 'html' )[0].classList.add(`no-hover`);
  }
}

const Layout = ({ children }) => {

  useEffect(() => {
    document.getElementsByTagName( 'html' )[0].classList.add(`js`);
    registerTouchables();
    adjustOutsideLinks();
    setCanHover();
  });

  return (
    <>
      <Nav />
      <StyledSiteWrap>
        {children}
        <Footer />
      </StyledSiteWrap>
    </>
  );
};

export default Layout;

export const Head = () => (
  <>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
    <meta name="theme-color" content="#000000" />
  </>
);

const StyledSiteWrap = styled.div`
  padding: 0 var(--gutter);
  max-width: var(--max-width);
  margin: 0 auto;
`;


import styled from "styled-components"

const Button = styled.button`
  position: relative;
  display: inline-block;
  color: var(--color-black);
  background-color: var(--color-white);
  font-size: 0.9375em;
  font-weight: 500;
  border: 0;
  //line-height: 1.5;
  margin: 0;
  user-select: none;
  //line-height: 2.65;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0.5em 1.25em;
  border-radius: 0.25em;
  white-space: nowrap;
  transition: opacity 0.3s ease;
  z-index: 0;

  .can-hover &:hover,
  &:active {
    opacity: 0.5;
  }
  
  &.is-dark {
    background-color: var(--color-black);
    color: var(--color-white);
  }

  &.is-small {
    height: 2.5em;
    line-height: 2.66;
    font-size: 0.8125em;
  }
  
  svg {
      display: inline-block;
      vertical-align: middle;
      height: 1.5em;
      margin-right: 0.625em;
      margin-top: -0.25em;
      path {
        fill: currentColor;
      }
  }
  svg:first-child {
      margin-left:-0.25em;
  }
  span {
      display: inline-block;
      vertical-align: middle;
  }
`

export default Button
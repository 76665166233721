const links = [
  {
    title: "Journalism",
    links: [
      {
        title: "Reporting",
        url: "/reporting",
        label: "Reporting"
      },
      {
        title: "Podcast",
        url: "/the-outlaw-ocean-podcast",
        label: "Podcast"
      },
      {
        title: "Dispatches from The Outlaw Ocean",
        url: "/dispatches",
        label: "Dispatches"
      },
      {
        title: "The Outlaw Ocean by Ian Urbina",
        url: "/the-outlaw-ocean-by-ian-urbina",
        label: "Book"
      }
    ]
  },
  {
    title: "Initiatives",
    links: [
      {
        title: "The Outlaw Ocean Institute",
        url: "/the-outlaw-ocean-institute",
        label: "Institute"
      },
      {
        title: "The Outlaw Ocean Mural Project",
        url: "https://theoutlawoceanmuralproject.com",
        label: "Murals"
      },
      {
        title: "The Outlaw Ocean Music Project",
        url: "https://www.theoutlawoceanmusic.com",
        label: "Music"
      }
    ]
  },
  {
    title: "Organization",
    links: [
      {
        title: "About",
        url: "/about",
        label: "About"
      },{
        title: "Appearances",
        url: "/appearances",
        label: "Appearances"
      }
      /*{
        title: "Coverage",
        url: "/coverage",
        label: "Coverage"
      }*/
    ]
  }
];

export default links;
import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import IconFacebook from "../svg/IconFacebook";
import IconInstagram from "../svg/IconInstagram";
import IconTwitter from "../svg/IconTwitter";
import IconYouTube from "../svg/IconYouTube";
import IconSpotify from "../svg/IconSpotify";
import IconSubstack from "../svg/IconSubstack";
import Support from "../SupportOptions";
import links from "./links";

const Footer = props => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            contactEmail
            social {
              twitter
              facebook
              instagram
              youtube
              spotify
              substack
            }
          }
        }
      }
    `
  )

  const social = data.site.siteMetadata.social;

  return (
    <>
      <Support />
      <StyledFooter id="donate">
        {/*<NewsletterSubscriptionForm />*/}
        <nav>
          {links.map((section, index) => (
            <dl key={`footer-section-${index}`}>
              <dt>{section.title}</dt>
              {section.links.map((link, index) => (
                <dd key={`footer-link-${index}`}>
                  <Link to={link.url} title={link.title}>{link.label}</Link>
                </dd>
              ))}
            </dl>
          ))}
          <dl>
            <dt>Follow</dt>
            {social.twitter &&
              <dd><a href={social.twitter} target="_blank" rel="noopener noreferrer" title={`On Twitter`}><IconTwitter /></a></dd>
            }
            {social.facebook &&
              <dd><a href={social.facebook} target="_blank" rel="noopener noreferrer" title={`On Facebook`}><IconFacebook /></a></dd>
            }
            {social.instagram &&
              <dd><a href={social.instagram} target="_blank" rel="noopener noreferrer" title={`On Instagram`}><IconInstagram /></a></dd>
            }
            {social.youtube &&
              <dd><a href={social.youtube} target="_blank" rel="noopener noreferrer" title={`On YouTube`}><IconYouTube /></a></dd>
            }
            {social.spotify &&
              <dd><a href={social.spotify} target="_blank" rel="noopener noreferrer" title={`On Spotify`}><IconSpotify /></a></dd>
            }
            {social.substack &&
              <dd><a href={social.substack} target="_blank" rel="noopener noreferrer" title={`On Substack`}><IconSubstack /></a></dd>
            }
          </dl>
        </nav>
        <div rel="contentinfo">
          <a href={`mailto:${data.site.siteMetadata.contactEmail}`} title={`Email us: ${data.site.siteMetadata.contactEmail}`}>{data.site.siteMetadata.contactEmail}</a>
          <span>&copy; 2024 {data.site.siteMetadata.title}</span>
        </div>
      </StyledFooter>
    </>
  )
}

export default Footer

const StyledFooter = styled.footer`

  position: relative;
  z-index: 1;
  color: var(--theme-text-against-dark);
  background-color: var(--color-black);
  padding: 3em 0 2em;
  max-width: 60em;
  @media ( min-width: 48em ) {
    padding: 4em 0 2em;
  }
  margin: 0 auto;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: inherit;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    border-top: 1px solid rgba(255,255,255,0.2);
  }

  nav {
    display: flex;
    align-items: flex-start;
    gap: 4em;
    margin-bottom: 1em;
    margin-top: 2em;
    @media ( max-width: 48em ) {
      display: grid;
      grid-template: auto / 1fr 1fr;
      margin-bottom: 1.5em;
    }
  }
  a {
    transition: opacity 0.3s ease;
    .can-hover &:hover,
    &:active {
      opacity: 0.5;
    }
  }
  dl {
    display: grid;
    gap: 0.5em;
    margin: 0;
    dt {
      //font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875em;
      font-weight: 700;
      margin-bottom: 0.375em;
      opacity: 0.7;
      letter-spacing: 0.075em;
    }
    dd {
      padding: 0;
      margin: 0;
      font-weight: var(--font-sans-weight);
    }
  }
  dl:last-of-type {
    grid-template: auto / 1fr 1fr 1fr;
    gap: 0.75em 1.125em;
    justify-self: start;
    @media ( min-width: 48em ) {
      grid-template: auto / 1fr 1fr;
      justify-self: end;
      margin-left: auto;
    }
    dt {
      grid-column: 1 / span 2;
      @media ( max-width: 48em ) {
        grid-column: 1 / span 3;
      }
    }
    a {
      position: relative;
      display: flex;
      height: 1.25em;
      width: 1.25em;
      align-items: center;
      //opacity: 0.5;
      transform: translate3d(0,0,0);
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: currentColor;
        }
      }
    }
  }

  [rel="contentinfo"] {
    span,
    a {
      font-size: 0.6875em;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      transition: opacity 0.3s ease;
    }
    a {
      transition: opacity 0.2s ease;
      .can-hover &:hover,
      &:active {
        opacity: 0.5;
        text-decoration-line: underline;
        text-decoration-thickness: 0.75px;
        text-underline-offset: 0.25em;
        text-decoration-color: var(--theme-text-against-dark-medium);
      }
    }
  }
  @media ( max-width: 48em ) {
    [rel="contentinfo"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      margin-top: 4em;
    }
  }

  @media ( min-width: 48em ) {
    [rel="contentinfo"] {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  @media ( min-width: 60em ) {
    display: grid;
    grid-template: auto / 1fr;
    align-items: center;
    gap: 6em;
    aside {
      grid-column: 2;
    }
    nav {
      grid-column: 1;
      grid-row: 1;
    }
    [rel="contentinfo"] {
      grid-column: 1 ;
    }
  }

`